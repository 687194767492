import { memo } from 'react'
import PropTypes from 'prop-types'

const MobileSearchResultHead = memo((props) => {
  const { onClick, title } = props

  return (
    <>
      <div className="result-head d-block d-lg-none">
        <span onClick={onClick} className="back">
          <svg xmlns="http://www.w3.org/2000/svg" className="jsx-995885002">
            <g
              fill="none"
              fillRule="evenodd"
              stroke="#333"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="jsx-995885002"
            >
              <path
                d="M16 6L0 6M6 12L0 6 6 0"
                transform="translate(1 1)"
                className="jsx-995885002"
              ></path>
            </g>
          </svg>
          {title}
        </span>
      </div>

      <style jsx global>{`
        .result-head {
          height: 52px;
          padding: 0 16px 0;
          background-color: #fff;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 52px;
          position: relative;
          z-index: 1;
          width: 100%;
          border-bottom: .5px solid #d8d8d8;
          border-top: .5px solid #d8d8d8;

          .back {
            color: #333;
            font-size: 14px;
            -webkit-text-decoration: none;
            text-decoration: none;

            svg {
              width: 15px;
              height: 17px;
              margin-right: 10px;
            }
          }

          .search {
            font-size: 14px;
            color: #1298e6;
          }
        }
      `}</style>
    </>
  )
})

MobileSearchResultHead.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
}

MobileSearchResultHead.defaultProps = {
  onClose: () => {},
  open: false,
}

export default MobileSearchResultHead
