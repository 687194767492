import { memo } from 'react'
import { useGlobalContext } from 'contexts'
import { menuItems } from 'models/header'
import styles from './index.module.scss'
import HeaderMenu from '../headermenu'

const HomeHeader2 = memo(({ onLoginDialogOpen }) => {
  const mainUrl2 = process.env.NEXT_PUBLIC_MAIN_URL2

  const { isLogin } = useGlobalContext()

  const ProfilButton = () => {
    return (
      <a
        className={`${styles.btn} btn ${styles['btn-secondary']}`}
        href={`${mainUrl2}/profil`}
      >
        Profil
      </a>
    )
  }

  return (
    <nav className={`${styles['main-header']} navbar fixed-top`}>
      <div className="container headerContainer">
        <div className={styles.leftSide}>
          <HeaderMenu menuItems={menuItems} className="d-flex d-lg-none" />
          <a
            title="İşin Olsun"
            href={mainUrl2}
            className={`${styles['navbar-brand']}`}
          >
            İşin Olsun
          </a>
        </div>

        <div className={`${styles.rightSide} ${styles.links}`}>
          {menuItems.map((link, index) => (
            <a key={index} className="d-none d-lg-block" href={link.url}>
              {link.label}
            </a>
          ))}
          {onLoginDialogOpen && (
            <div className={styles.accountArea}>
              {isLogin ? (<ProfilButton />) : (
                <a className={`${styles.btn} btn`} onClick={onLoginDialogOpen}>
                  Giriş Yap / Üye Ol
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  )
})

export default HomeHeader2
