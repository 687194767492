import { memo, useRef, useState, useEffect } from 'react'
import dynamic from 'next/dynamic'

import SearchInput2 from 'components/ui/searchinputs2'
import qs from 'qs'
import { useGlobalContext } from 'contexts'
import { locationMinLength, positionMinLength } from 'utils/general'

// import LocationTooltip from 'components/ui/locationtooltip'

const LocationTooltip = dynamic(() => import('components/ui/locationtooltip'))

const HeaderSearch = memo((props) => {
  const timeoutPositionRef = useRef(null)
  const timeoutLocationRef = useRef(null)
  const { isLogin, loginCallBack, setLoginCallBack } = useGlobalContext()

  const submitButtonRef = useRef(null)

  const [isPositionPopularShow, setIsPositionPopularShow] = useState(false)
  const [isLocationPopularShow, setIsLocationPopularShow] = useState(false)

  const [callBackFuncName, setCallBackFuncName] = useState(null)

  const {
    lastSearchPositions,
    lastSearchLocations,
    locationProcess,
    popularList,
    pageUri,
  } = props
  const positionList = props.positionList || []
  const locationList = props.locationList || []

  const searchPageUrl = pageUri ? pageUri.slashPath : '/is-ilanlari'

  useEffect(() => {
    if (!loginCallBack) return

    if (callBackFuncName == 'onLocationHome' && isLogin) {
      onUseHomeLocation()
      setCallBackFuncName(null)
      setLoginCallBack(false)
    }
  }, [loginCallBack])

  useEffect(() => {
    if (props.positionList?.length > 0) setIsPositionPopularShow(false)
  }, [props.positionList])

  useEffect(() => {
    if (props.locationList?.length > 0) setIsLocationPopularShow(false)
  }, [props.locationList])

  const onFormSubmit = (e) => {
    e.preventDefault()
    props.onFormSubmit()
  }

  const onPositionFocus = () => {
    clearTimeout(timeoutPositionRef.current)
    if (props.positionKeyword?.length > 1) {
      setIsPositionPopularShow(false)
    } else {
      setIsPositionPopularShow(true)
    }
    if (positionList.length == 0) {
      props.onPositionChange(props.positionKeyword)
    }
  }

  const onPositionBlur = () => {
    timeoutPositionRef.current = setTimeout(() => {
      timeoutPositionRef.current = null
      setIsPositionPopularShow(false)
    })
  }

  const onLocationFocus = () => {
    clearTimeout(timeoutLocationRef.current)
    if (props.locationKeyword?.length > 1) {
      setIsLocationPopularShow(false)
      if (locationList.length == 0) {
        props.onLocationChange(props.locationKeyword)
      }
    } else {
      setIsLocationPopularShow(true)
    }
  }

  const onLocationBlur = () => {
    timeoutLocationRef.current = setTimeout(() => {
      timeoutLocationRef.current = null
      setIsLocationPopularShow(false)
    })
  }

  const onPositionChange = (e, isSelect) => {
    props.onPositionChange(e, isSelect)
    if (isPositionPopularShow && e?.length > 0) {
      setIsPositionPopularShow(false)
    }
  }

  const onLocationChange = (e, isSelect) => {
    props.onLocationChange(e, isSelect)
    if (isLocationPopularShow && e?.length > 0) {
      setIsLocationPopularShow(false)
    }
  }

  const onUseCurrentLocation = () => {
    props.onPullCurrentLocation()
    setIsLocationPopularShow(false)
  }

  const onUseHomeLocation = () => {
    if (!isLogin) {
      setCallBackFuncName('onLocationHome')
      props.onLoginDialogOpen('İşlerde "Evime Yakın" araması yapabilmek için')
      return
    }
    props.onPullHomeLocation()
    setIsLocationPopularShow(false)
  }

  const onClearLastPosition = () => {
    if (props.onClearLastPosition) props.onClearLastPosition()
  }
  const onClearLastLocation = () => {
    if (props.onClearLastLocation) props.onClearLastLocation()
  }

  const onPositionKeyDown = (e) => {
    if (e && e.key && e.key == 'Enter') {
      if (e.target.value && e.target.value.length >= positionMinLength) {
        if (submitButtonRef.current) {
          submitButtonRef.current.click()
        }
      }
    }
  }

  const onLocationKeyDown = (e) => {
    if (e && e.key && e.key == 'Enter') {
      if (e.target.value && e.target.value.length >= locationMinLength) {
        if (submitButtonRef.current) {
          submitButtonRef.current.click()
        }
      }
    }
  }

  const onClickPopularPosition = (e, item) => {
    e.preventDefault()
    // if (!props.locationKeyword || props.locationKeyword.length == 0) {
    //   locationInputRef.current.focus()
    // }

    props.onPositionChange(item.text, true, 1)
  }

  const onClickLocationHistory = (e, item) => {
    e.preventDefault()
    // if (!props.positionKeyword || props.positionKeyword.length == 0) {
    //   positionInputRef.current.focus()
    // }
    props.onLocationChange(item.lc, true, 2)
  }

  const onClickPositionHistory = (e, item) => {
    e.preventDefault()
    // if (!props.locationKeyword || props.locationKeyword.length == 0) {
    //   locationInputRef.current.focus()
    // }
    props.onPositionChange(item, true, 2)
  }

  const positionPopularShowCheck = () => {
    return (
      isPositionPopularShow && (popularList || lastSearchPositions.length > 0)
    )
  }

  return (
    <>
      <form
        action={searchPageUrl}
        data-test="header-search-form"
        onSubmit={(e) => onFormSubmit(e)}
        className="form-inline header-search-form"
      >
        <div
          data-test="position-search"
          className="input-icon position"
          onBlur={(e) => onPositionBlur(e)}
          onFocus={(e) => onPositionFocus(e)}
          tabIndex="0"
        >
          <SearchInput2
            defaultKeyword={props.positionKeyword}
            inputClassName="v2"
            menuClassName="v2"
            items={positionList}
            name="kw"
            id="position-search"
            labelKey="name"
            placeholder="Aradığın iş"
            icon="icon-search-thick"
            onValueChange={onPositionChange}
            minLength={positionMinLength}
            dataTest="position-search-input"
            onKeyDown={onPositionKeyDown}
          />
          <div
            data-test="position-popular-menu"
            className={`dropdown-menu show popular-menu ${
              positionPopularShowCheck() ? '' : 'd-none'
            }`}
          >
            {isPositionPopularShow && lastSearchPositions.length > 0 && (
              <div data-test="position-last-search" className="result last">
                <h2>
                  Arama Geçmişi{' '}
                  <small onClick={onClearLastPosition}>Temizle</small>
                </h2>
                <div className="result-list">
                  {lastSearchPositions.map((d, i) => (
                    <a
                      key={i}
                      title={d}
                      onClick={(e) => onClickPositionHistory(e, d)}
                      href={`${process.env.NEXT_PUBLIC_MAIN_URL2}${searchPageUrl}?kw=${d}`}
                      rel="nofollow"
                    >
                      <span>{d}</span>
                    </a>
                  ))}
                </div>
              </div>
            )}

            {popularList && (
              <div
                data-test="position-popular-search"
                className={`result popular ${
                  isPositionPopularShow && lastSearchPositions.length > 0
                    ? 'd-none'
                    : ''
                }`}
              >
                <h2>{popularList.title}</h2>
                <div className="result-list">
                  {popularList.items.map((d, i) => (
                    <a
                      key={i}
                      title={d.text}
                      onClick={(e) => onClickPopularPosition(e, d)}
                      href={`${process.env.NEXT_PUBLIC_MAIN_URL2}${searchPageUrl}/${d.url}`}
                    >
                      <i className={d.icon}></i>
                      <span>{d.text}</span>
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div data-test="location-search" className="input-icon location">
          <div
            onBlur={(e) => onLocationBlur(e)}
            onFocus={(e) => onLocationFocus(e)}
            tabIndex="0"
          >
            <SearchInput2
              defaultKeyword={props.locationKeyword}
              inputClassName="v2"
              menuClassName="v2"
              items={locationList}
              name="lc"
              id="location-search"
              labelKey="name"
              placeholder="İş aradığın yer"
              icon="icon-location-thick"
              onValueChange={onLocationChange}
              minLength={locationMinLength}
              dataTest="location-search-input"
              onKeyDown={onLocationKeyDown}
            />
            {isLocationPopularShow && (
              <div
                data-test="location-popular-menu"
                className="dropdown-menu show popular-menu"
              >
                <div data-test="location-pull" className="result last">
                  <div className="result-list location">
                    <div
                      onClick={onUseCurrentLocation}
                      title="Şu anki konumumu kullan"
                      className="result-list-item location"
                    >
                      <i className="icon-location-pull"></i> Şu anki konumumu
                      kullan
                    </div>

                    <div
                      onClick={onUseHomeLocation}
                      title="Evimin konumunu kullan"
                      className="result-list-item"
                    >
                      <i className="icon-home"></i> Evimin konumunu kullan
                    </div>
                  </div>
                </div>
                {lastSearchLocations.length > 0 && (
                  <div data-test="location-last-search" className="result last">
                    <div className="divider"></div>
                    <h2>
                      Arama Geçmişi{' '}
                      <small onClick={onClearLastLocation}>Temizle</small>
                    </h2>
                    <div className="result-list">
                      {lastSearchLocations.map((d, i) => (
                        <a
                          key={i}
                          title={d.lc}
                          onClick={(e) => onClickLocationHistory(e, d)}
                          href={
                            `${process.env.NEXT_PUBLIC_MAIN_URL2}${searchPageUrl}?` +
                            qs.stringify({ ...d })
                          }
                          rel="nofollow"
                        >
                          <span>{d.lc}</span>
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {props.showLocationTooltip > 0 && (
            <LocationTooltip
              locationText={props.locationTooltipText}
              status={props.showLocationTooltip}
              onClose={props.onCloseTooltip}
            />
          )}
          {!isLocationPopularShow &&
            !props.locationKeyword &&
            !locationProcess && (
            <span onClick={onUseCurrentLocation} className="location-input">
              <i className="icon-location-pull"></i>
            </span>
          )}
          {locationProcess && (
            <span className="location-input">
              <img
                className="loading-img"
                src={`${process.env.NEXT_PUBLIC_CDN_URL}/_next/static/images/loading.gif`}
                alt="Yükleniyor Görseli"
              />
            </span>
          )}
        </div>
        <input
          ref={submitButtonRef}
          type="submit"
          className="btn btn-search"
          data-test="job-search-button"
          value="İş Bul"
        />
      </form>
      <style jsx global>{`
        .header-search-form {
          max-width: 644px;
          width: 100%;
          gap: 16px;
          justify-content: space-between;

          .input-icon {
            position: relative;
            flex: 1;

            &.position {
              max-width: 360px;
            }

            &.location {
              max-width: 180px;
            }

            .location-input {
              z-index: 1;
              position: absolute;
              right: 0;
              cursor: pointer;
              padding: 8px;
              display: inline-block;
              color: #1298e6;
              font-size: 16px;
              bottom: -1px;
              right: 7px;

              .loading-img {
                width: 16px;
                vertical-align: baseline;
                z-index: 3;
              }
            }

            .popular-menu {
              width: 100%;
              min-width: 276px;
              background-color: #fff;
              border-radius: 5px;
              box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.14);
              border: solid 1px #d8d8d8;
              padding: 6px 0 6px 0;
              margin-top: 8px;
              overflow: hidden;

              .result {
                h2 {
                  color: #333;
                  font-size: 14px;
                  margin-top: 10px;
                  margin-bottom: 8px;
                  font-weight: 500;
                  padding: 0 16px 0 16px;

                  small {
                    float: right;
                    font-size: 14px;
                    color: #333;
                    font-weight: normal;
                    cursor: pointer;
                  }
                }
                &.last {
                  .divider {
                    border-bottom: solid 1px #d8d8d8;
                    margin-bottom: 16px;
                  }
                  .result-list {
                    &.location i {
                      margin-right: 9px;
                      width: 16px;
                      font-size: 16px;
                    }
                    a,
                    .result-list-item {
                      cursor: pointer;
                      span {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                      }
                    }
                  }
                }
                &-list {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  a,
                  .result-list-item {
                    background-color: #fff;
                    font-size: 14px;
                    color: #333;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    padding: 8px 16px 8px 16px;

                    &:hover,
                    &:focus {
                      text-decoration: none;
                      color: #333;
                      background-color: #f4f4f8;
                    }

                    &:focus,
                    &:active {
                      text-decoration: none;
                    }

                    i {
                      font-size: 24px;
                      text-align: left;
                      font-size: 23px;
                      width: 23px;
                      margin-right: 17px;
                    }
                    span {
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    }
                    &:last-child {
                      margin-bottom: 0;
                    }
                    &.location {
                      color: #1298e6;
                    }
                  }
                }
              }
            }

            &:hover,
            &:focus,
            &:focus-within {
              box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);

              .clear-input.show {
                display: inherit;
              }

              .search-input2.v2 input {
                padding: 0 42px 0 40px;
              }
            }
          }

          .btn-search {
            width: 72px;
            height: 36px;
            border-radius: 5px;
            background: #e0212b;
            color: #fff;
            font-weight: 500;
            font-size: 14px;

            &:hover,
            &:focus {
              color: #fff;
              border-color: #ca111b;
              outline: none;
              background: #ca111b;
            }
          }
          @media (max-width: 1170px) {
            max-width: 580px;
          }
        }
      `}</style>
    </>
  )
})

export default HeaderSearch
