import { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useGlobalContext } from 'contexts'

import services from 'services'
import { Router } from 'routes'

import {
  removeCookies,
  checkCookies,
  getCookies,
  setCookies,
} from 'utils/cookies-next'
import {
  geoLocationProcess,
  isAvailableSessionStorage,
  locationMinLength,
  positionMinLength,
  setStorageCandidate,
  toUrl,
  isAvailablelocalStorage,
  setGeoLocation,
  getAddress,
  setLocationAddress,
} from 'utils/general'
import { popularPositionList, popularServiceList } from 'models/popular'

import Footer2 from '@components/ui/footer-2'
import HeaderSearch from 'components/ui/headersearch'
import PageHead from '@components/ui/pagehead'
import MobileSearchResultHead from '@components/ui/mobilesearchresulthead'
import BgImageLazyLoad from 'components/ui/bgimagelazyload'
import classNames from 'classnames'
import HomeHeader2 from '@components/ui/homeheader2'
import { positionListSearch } from 'utils/mapping'
import { useDebounceCallback } from 'utils/useDebounceCallback'
import HeaderMenu from '@components/ui/headermenu'
import { menuItems } from 'models/header'
import useDebounce from '../../utils/useDebounce'
import styles from './default.module.scss'

const AgreementDialog = dynamic(() => import('components/ui/agreementdialog'))
const ActivationDialog = dynamic(() => import('components/ui/activationdialog'))
const MobileNavbarFormModal = dynamic(() =>
  import('components/ui/mobilenavbarformmodal')
)
const LocationPermissionDialog = dynamic(() =>
  import('components/ui/locationpermissiondialog')
)
const LoginRegisterButtons = dynamic(() =>
  import('components/ui/loginregisterbuttons')
)
const AccountHeaderArea = dynamic(() =>
  import('components/ui/accountheaderarea')
)
const ResponsiveLoginMenu = dynamic(() =>
  import('components/ui/responsiveloginmenu')
)
const ResponsiveAccountMenu = dynamic(() =>
  import('components/ui/responsiveaccountmenu')
)

const accountType = 2

const DefaultLayout = (props) => {
  const {
    isLogin,
    setOnlogin,
    setJobSearchLoading,
    allPositionList,
    setOnAllPositionList,
    positionKeyword,
    setPositionKeyword,
    positionName,
    setPositionName,
    locationKeyword,
    setLocationKeyword,
    searchLocation,
    setSearchLocation,
  } = useGlobalContext()

  const [positionEmptyLabel, setPositionEmptyLabel] = useState()
  const [locationEmptyLabel, setLocationEmptyLabel] = useState()
  const [isSearchProcess, setIsSearchProcess] = useState(false)
  const [isTempSearchProcess, setIsTempSearchProcess] = useState(false)
  const [locationProcess, setLocationProcess] = useState(false)

  const [tempFilter, setTempFilter] = useState()
  const [positionList, setPositionList] = useState([])
  const [locationList, setLocationList] = useState([])
  const [lastSearchPositions, setLastSearchPositions] = useState([])
  const [lastSearchLocations, setLastSearchLocations] = useState([])
  const [lastSearchPosAndLoc, setLastSearchPosAndLoc] = useState([])

  const [openMobileSearch, setOpenMobileSearch] = useState(false)
  const [locationPermissionDialogOpen, setLocationPermissionDialogOpen] =
    useState(props.locationPermissionDialogOpen)

  const [activationDialog, setActivationDialog] = useState({})

  const [account, setAccount] = useState({})

  const [agreementDialog, setAgreementDialog] = useState({})
  const [toOpenAgreementList, setToOpenAgreementList] = useState([])
  const [notificationCount, setNotificationCount] = useState()
  const [showLocationTooltip, setShowLocationTooltip] = useState()
  const [locationTooltipText, setLocationTooltipText] = useState()

  const router = useRouter()
  const debouncedLocationValue = useDebounce(locationKeyword)

  const cookieNameAdd = props.isServicePage ? '_Service' : ''
  const popularList = props.isServicePage
    ? popularServiceList
    : popularPositionList
  const routeName = props.isServicePage ? 'servicejobs' : 'jobsearch'

  const localStorageKeywords = 'BlueCollar_lastSearchKeywords' + cookieNameAdd
  const localStorageLocations =
    'BlueCollar_lastSearchLocationsViewport' + cookieNameAdd
  const localStorageKeywordsLocations =
    'BlueCollar_lastSearchKeywordsLocationsViewport' + cookieNameAdd

  useEffect(() => {
    if (isLogin) {
      notificationCheck()
    }

    const swapCookiesToLocalStorage = (key, setter) => {
      if (isAvailablelocalStorage()) {
        const data = getCookies(null, key) || localStorage.getItem(key)
        if (data) {
          setter(JSON.parse(data))
          localStorage.setItem(key, data)
          removeCookies(null, key)
        }
      }
    }

    swapCookiesToLocalStorage(localStorageKeywords, setLastSearchPositions)
    swapCookiesToLocalStorage(localStorageLocations, setLastSearchLocations)
    swapCookiesToLocalStorage(
      localStorageKeywordsLocations,
      setLastSearchPosAndLoc
    )

    queryProcessControl()
  }, [])

  useEffect(() => {
    if (isLogin) {
      getAccount()
      checkAgreement()
    }
  }, [isLogin])

  useEffect(() => {
    const handleStart = (url) => {
      //loading start
    }
    const handleStop = () => {
      //loading end
      // if (openMobileSearch) {
      //onCloseMobileSearch()
      setLocationProcess(false)
      setJobSearchLoading(false)
      //}

      locationTooltipCheck()
      anchorScrollIntoView()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [])

  useEffect(() => {
    if (!isSearchProcess) return
    if (!openMobileSearch) {
      onSearchSubmitProcess()
    } else if (locationKeyword?.length > locationMinLength) {
      onSearchSubmitProcess()
    }
  }, [positionKeyword])

  useEffect(() => {
    if (!isSearchProcess) return
    if (!openMobileSearch) {
      onSearchSubmitProcess()
    } else if (positionKeyword?.length > positionMinLength) {
      onSearchSubmitProcess()
    }
  }, [searchLocation])

  useEffect(() => {
    if (!isTempSearchProcess) return
    if (!openMobileSearch) {
      onSearchSubmitProcess()
    } else if (positionKeyword && positionKeyword.length > positionMinLength) {
      onSearchSubmitProcess()
    }
  }, [locationKeyword])

  const getAccount = () => {
    if (isAvailablelocalStorage() && localStorage.getItem('account')) {
      const accountItem = JSON.parse(localStorage.getItem('account'))
      setAccount(accountItem)
    }
  }

  const ga4Event = (type, loc) => {
    let city
    let district
    if (loc) {
      let location = loc.split(', ')
      district = toUrl(location[location.length - 2])
      city = toUrl(location[location.length - 1])
    }

    dataLayer.push({
      event: 'ga4_event',
      event_name: 'search_criteria',
      event_parameters: {
        ...(city ? { city } : {}),
        ...(district ? { district } : {}),
        content_type: type,
      },
    })
  }

  const onSearchSubmitProcess = () => {
    searchOnSubmit()
    setIsSearchProcess(false)
    setIsTempSearchProcess(false)
  }

  const notificationCheck = async () => {
    try {
      const resp = await services.wrapper(
        null,
        services.configs.notificationcenter.notifications.count.get
      )
      if (resp.data && resp.data.result) {
        setNotificationCount(resp.data.result)
      }
    } catch (err) {
      //
    }
  }

  const queryProcessControl = () => {
    if (!router.query || isLogin) {
      locationControl()
      return
    }

    if ('login' in router.query) onActivationDialogOpen('login')
    else if ('register' in router.query) onActivationDialogOpen('register')
    else locationControl()
  }

  const locationControl = () => {
    if (['/jobsearch', '/servicejobs'].includes(router.route)) {
      if (
        checkCookies(null, 'BlueCollar_UserLocationLat') &&
        checkCookies(null, 'BlueCollar_UserLocationLng') &&
        checkCookies(null, 'BlueCollar_UserLocationAddress') &&
        checkCookies(null, 'BlueCollar_UserLocationShortAddress')
      ) {
        locationTooltipCheck()
      } else {
        geoLocationControl()
      }
    }
  }

  const checkAgreement = async () => {
    const agreementList = []
    if (!(await clarificationTextCheck())) {
      const agreement = await setClarificationText()
      if (agreement) agreementList.push(agreement)
    }

    if (!(await candidateAgreementCheck())) {
      const agreement = await setCandidateAgreementText()
      if (agreement) {
        agreementList.push(agreement)
      }
    }
    checkToOpenAgreementList(agreementList)
  }

  const checkToOpenAgreementList = (agreementList) => {
    if (agreementList.length < 1) {
      return
    }

    const agreement = agreementList[0]
    let disableClickOutside = false
    let hideCloseButton = false

    if (agreement.type == 'clarificationAgreement') {
      postClarificationAccount(agreement.agreementId)
    } else if (agreement.type == 'candidateAgreement') {
      disableClickOutside = true
      hideCloseButton = true
    }

    setAgreementDialog({
      agreementId: agreement.agreementId,
      agreementType: agreement.type,
      agreementDescription: agreement.text,
      disableClickOutside: disableClickOutside,
      hideCloseButton: hideCloseButton,
      showAgreeButton: ['candidateAgreement', 'personalAgreement'].includes(
        agreement.type
      ),
      open: true,
    })

    agreementList.shift()
    setToOpenAgreementList(agreementList)
  }

  const geoLocationControl = async () => {
    if (!navigator?.geolocation) {
      return
    }

    setLocationProcess(true)
    locationPermissionDialogClose()
    
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        setJobSearchLoading(true)
        setGeoLocation(position)

        let respAddress
        try {
          respAddress = await getAddress(position)
        } catch (err) {
          //
        }
      
        if (respAddress?.data?.result) {
          setLocationAddress(respAddress.data.result)

          let query = {}
          Object.assign(query, props.query)
          delete query.pn
          Router.pushRoute(props.routeName, query)
        }
      },
      () => {
        setLocationProcess(false)
        setJobSearchLoading(false)
        locationTooltipCheck()
      }
    )
  }

  const locationTooltipCheck = () => {
    setLocationTooltipText(null)
    setShowLocationTooltip(0)

    if (!['/jobsearch', '/servicejobs'].includes(router.route)) return
    if (searchLocation?.lc) return

    const q = props.query || {}
    if (!q.jobSort || q.jobSort == '3') {
      const address = getCookies(null, 'BlueCollar_UserLocationAddress')
      const shortAddress = getCookies(
        null,
        'BlueCollar_UserLocationShortAddress'
      )

      if (address && shortAddress) {
        if (
          isAvailableSessionStorage() &&
          !sessionStorage.getItem('locationExistsTooltip')
        ) {
          setLocationTooltipText(shortAddress)
          setShowLocationTooltip(1)
          sessionStorage.setItem('locationExistsTooltip', true)
        }
      } else {
        if (
          isAvailableSessionStorage() &&
          !sessionStorage.getItem('noLocationTooltip')
        ) {
          setShowLocationTooltip(2)
          //onLocationPermissionDialogOpen()
          sessionStorage.setItem('noLocationTooltip', true)
        }
      }
    }
  }

  // useEffect(() => {
  //   if (props.mobileSearchHead != mobileSearchHead)
  //     setMobileSearchHead(props.mobileSearchHead)
  // }, [props.mobileSearchHead])

  useEffect(() => {
    if (
      props.locationPermissionDialogOpen != locationPermissionDialogOpen &&
      props.locationPermissionDialogOpen
    ) {
      onLocationPermissionDialogOpen(true)
    }
  }, [props.locationPermissionDialogOpen])

  useEffect(() => {
    if (
      props.activationDialogOpen != activationDialog.open &&
      props.activationDialogOpen
    ) {
      onActivationDialogOpen('login', props.activationMessage)
    }
  }, [props.activationDialogOpen])

  useEffect(() => {
    if (debouncedLocationValue?.length >= locationMinLength) {
      getLocationSearch(debouncedLocationValue)
    }
  }, [debouncedLocationValue])

  // useEffect(() => {
  //   if (!allPositionList?.length) {
  //     setOnAllPositionList()
  //   }
  // }, [positionKeyword])

  useEffect(() => {
    if (
      allPositionList?.length &&
      positionKeyword?.length >= positionMinLength
    ) {
      getPositionSearch(positionKeyword)
    }
  }, [allPositionList])

  const getLocationSearch = (e) => {
    setLocationEmptyLabel()
    const params = {
      keyword: e,
    }
    try {
      services
        .wrapper(null, services.configs.places.suggests.get, params)
        .then((resp) => {
          if (resp?.data?.result) {
            if (resp.data.result?.length == 0) {
              setLocationEmptyLabel('Araman için sonuç bulunamadı.')
            }
            setLocationList(resp.data.result)
          }
        })
    } catch (error) {
      //
    }
  }

  const getPositionSearch = useDebounceCallback((query) => {
    const positionJobType = props.isServicePage && 2

    const result = positionListSearch(
      allPositionList,
      query,
      positionJobType,
      positionMinLength
    )

    if (Array.isArray(result)) setPositionList(result)
    else {
      setPositionList([])
      setPositionEmptyLabel('Araman için sonuç bulunamadı.')
    }
  }, 300) //debounce süresi

  const onPositionInputChange = (e, isSelect, type) => {
    const isPopular = type == 1 ? true : false
    const isHistory = type == 2 ? true : false
    if (isHistory) {
      ga4Event('arama-gecmisi')
    }

    if (isPopular && isSelect) {
      ga4Event('populer-meslekler')
    }
    if (!isHistory && !isPopular && isSelect) {
      ga4Event('autocomplete-job')
    }
    setIsSearchProcess(false)
    if (isSelect) setIsSearchProcess(true)

    if (
      !isSelect &&
      allPositionList?.length &&
      e?.length >= positionMinLength
    ) {
      getPositionSearch(e)
    } else if (!allPositionList?.length) setOnAllPositionList()

    if (!e?.length) {
      setPositionList([])
    }

    setPositionKeyword(e)

    if (isSelect) setPositionName(e)
    else setPositionName()
  }

  const onPullCurrentLocation = async () => {
    ga4Event('su-anki-konum')
    setLocationProcess(true)
    const geo = await geoLocationProcess()

    if (geo) {
      setTempFilter({ useCurrentLocation: true })
      setSearchLocation({})
      setIsTempSearchProcess(true)
      setLocationKeyword(geo.location.address)

      if (openMobileSearch) {
        if (!positionKeyword || positionKeyword.length == 0) {
          document.getElementById('position-search-mobile')?.focus()
        }
      }
    } else {
      onLocationPermissionDialogOpen(true)
    }

    setLocationProcess(false)
  }

  const onPullHomeLocation = async () => {
    ga4Event('evimin-konumu')
    let userAddress = getCookies(null, 'BlueCollar_HomeAddress')

    if (!userAddress) {
      const candidate = await setStorageCandidate()
      userAddress = candidate?.address
    }

    if (userAddress) {
      setTempFilter({ useHomeLocation: true })
      setSearchLocation({})
      setIsTempSearchProcess(true)
      setLocationKeyword(userAddress)

      if (
        openMobileSearch &&
        (!positionKeyword || positionKeyword.length == 0)
      ) {
        document.getElementById('position-search-mobile')?.focus()
      }
    }
  }

  const onLocationInputChange = async (e, isSelect, type) => {
    const isHistory = type == 2 ? true : false
    if (isHistory) {
      ga4Event('arama-gecmisi')
    }
    if (!isHistory && isSelect) {
      ga4Event('autocomplete-location', e)
    }
    setIsSearchProcess(false)
    if (isSelect) setIsSearchProcess(true)

    setLocationKeyword(e)

    if (tempFilter) setTempFilter()
    if (!isSelect && e?.length >= locationMinLength) {
      setSearchLocation({ lc: e })
    }

    if (!e?.length) {
      setLocationList([])
      setSearchLocation({})
    }

    if (isSelect && e?.length > 0) {
      const params = {
        address: e,
      }
      let resp
      try {
        resp = await services.wrapper(
          null,
          services.configs.places.details.get,
          params
        )
      } catch (error) {
        //
      }

      if (resp?.data?.result) {
        const city = resp.data.result?.city?.toString()
        const town = resp.data.result?.townName?.toString()

        setSearchLocation({
          lc: resp.data.result?.name,
          ...(city && { city }),
          ...(town && { town }),
          brlat: resp.data.result?.viewportBottomRightLatitude?.toString(),
          brlon: resp.data.result?.viewportBottomRightLongitude?.toString(),
          tplat: resp.data.result?.viewportTopLeftLatitude?.toString(),
          tplon: resp.data.result?.viewportTopLeftLongitude?.toString(),
        })
      }
    }
  }

  const searchOnSubmit = () => {
    const searchPosition = {}

    if (positionKeyword) {
      searchPosition.kw = positionKeyword
      if (positionName) searchPosition.pos = positionName

      let arrObj = lastSearchPositions

      const index = arrObj.indexOf(searchPosition.kw)
      if (index > -1) {
        arrObj.splice(index, 1)
      }

      arrObj.unshift(searchPosition.kw)
      if (arrObj.length > 6) arrObj = arrObj.slice(0, 6)
      if (isAvailablelocalStorage())
        localStorage.setItem(localStorageKeywords, JSON.stringify(arrObj))
      setLastSearchPositions(arrObj)
    }

    if (searchLocation?.lc) {
      let arrObj = lastSearchLocations.filter(
        (e) => JSON.stringify(e) != JSON.stringify(searchLocation)
      )

      arrObj.unshift(searchLocation)
      if (arrObj.length > 4) arrObj = arrObj.slice(0, 4)
      if (isAvailablelocalStorage())
        localStorage.setItem(localStorageLocations, JSON.stringify(arrObj))
      setLastSearchLocations(arrObj)
    }

    //mobile search cookie start
    const lastText = []
    if (searchPosition?.kw) lastText.push(searchPosition)
    if (searchLocation?.lc) lastText.push(searchLocation)

    if (lastText.length > 0) {
      let arrObj = lastSearchPosAndLoc.filter(
        (e) => JSON.stringify(e) != JSON.stringify(lastText)
      )

      arrObj.unshift(lastText)
      if (arrObj.length > 6) arrObj = arrObj.slice(0, 6)
      if (isAvailablelocalStorage())
        localStorage.setItem(
          localStorageKeywordsLocations,
          JSON.stringify(arrObj)
        )
      setLastSearchPosAndLoc(arrObj)
    }
    //mobile search cookie end

    const searchParameters = {
      ...searchPosition,
      ...searchLocation,
      ...tempFilter,
    }

    dataLayer.push({
      event: 'ga4_event',
      event_name: 'search',
    })

    dataLayer.push({
      Category: 'İlan Listeleme',
      Action: 'İş Ara',
      Label: 'İş Ara',
      cd_search_location: searchLocation?.lc || undefined,
      cd_search_job_type: searchPosition?.kw || undefined,
      cd_pagetype: 'İlan Listeleme',
      cd_ad_id: undefined,
      cd_ad_name: undefined,
      cd_ad_company_name: undefined,
      cd_ad_location: undefined,
      cd_app_type: undefined,
      cd_list_position: undefined,
      cd_ad_company_sector: undefined,
      cm_ad_view: undefined,
      cm_list_count: undefined,
      cm_list_view: undefined,
      cm_app_download: undefined,
      cm_sms: undefined,
      cm_ad_apply: undefined,
      cm_ad_call: undefined,
      event: 'gaEvent',
    })

    // //redirect
    // window.location.href =
    //   `${process.env.NEXT_PUBLIC_MAIN_URL2}/is-ilanlari` +
    //   (Object.keys(searchParameters).length > 0
    //     ? '?' + qs.stringify(searchParameters)
    //     : '')
    onCloseMobileSearch()
    setJobSearchLoading(true)
    Router.pushRoute(routeName, { ...searchParameters })
  }

  const onOpenMobileSearch = () => {
    document.querySelector('body').classList.add('mobilesearch-open')
    setOpenMobileSearch(true)
  }

  const onCloseMobileSearch = () => {
    document.querySelector('body').classList.remove('mobilesearch-open')
    setOpenMobileSearch(false)
  }

  const onCloseTooltip = () => {
    setShowLocationTooltip(0)
    setLocationTooltipText(null)
  }

  const activationDialogClose = (result) => {
    setActivationDialog({})

    onLogin(result)

    if (openMobileSearch) {
      document.getElementById('location-search-mobile')?.focus()
    }
  }

  const onLogin = (result) => {
    if (result && typeof setOnlogin === 'function') {
      setOnlogin(true)
    }
  }

  const clarificationTextCheck = async () => {
    const params = {
      accountTypeId: accountType,
    }

    let resp = {}
    try {
      resp = await services.wrapper(
        null,
        services.configs.agreements.accounts.get,
        params
      )
    } catch (err) {
      return false
    }

    if (resp?.data?.result?.isApprovedLatestAgreement) {
      return true
    } else {
      return false
    }
  }

  const setClarificationText = async () => {
    let resp = {}
    try {
      resp = await services.wrapper(
        null,
        services.configs.agreements.personaldatas.get
      )
    } catch (err) {
      //
    }

    if (resp?.data?.result) {
      const result = resp.data.result

      const agreement = {
        type: 'clarificationAgreement',
        ...result,
      }
      return agreement
    }

    return false
  }

  const postClarificationAccount = async (agreementId) => {
    const params = {
      accountId: account.accountId,
      agreementClarificationId: agreementId,
    }

    try {
      await services.wrapper(
        null,
        services.configs.agreements.accounts.post,
        params
      )
    } catch (err) {
      //
    }
  }

  const candidateAgreementCheck = async () => {
    const params = {
      accountTypeId: accountType,
    }

    let resp = {}
    try {
      resp = await services.wrapper(
        null,
        services.configs.agreements.useraccounts.get,
        params
      )
    } catch (err) {
      return false
    }

    if (resp?.data?.result?.isApprovedLatestAgreement) {
      return true
    } else {
      return false
    }
  }

  const setCandidateAgreementText = async () => {
    let resp = {}
    try {
      resp = await services.wrapper(
        null,
        services.configs.agreements.candidate.get
      )
    } catch (err) {
      //
    }

    if (resp?.data?.result) {
      const result = resp.data.result

      const agreement = {
        type: 'candidateAgreement',
        ...result,
      }
      return agreement
    }
    return false
  }

  const postCandidateAgreement = async (agreementId) => {
    const params = {
      accountId: account.accountId,
      agreementUserId: agreementId,
    }

    try {
      await services.wrapper(
        null,
        services.configs.agreements.useraccounts.post,
        params
      )
    } catch (err) {
      //
    }
  }

  const onConfirmAgreement = () => {
    if (agreementDialog.agreementType == 'candidateAgreement') {
      postCandidateAgreement(agreementDialog.agreementId)
      onAgreementClose()
    }
  }

  const onAgreementClose = () => {
    setAgreementDialog({})

    if (toOpenAgreementList.length > 0) {
      checkToOpenAgreementList(toOpenAgreementList)
    }
  }

  const onActivationDialogOpen = (type, message) => {
    setActivationDialog({
      disableClickOutside: true,
      processType: type,
      activationMessage: message ? message : '',
      open: true,
    })
  }

  const onLocationPermissionDialogOpen = (directOpen) => {
    if (locationPermissionDialogOpen || activationDialog.open) return
    if (directOpen || !checkCookies(null, 'BlueCollar_UserLocationDisabled')) {
      setLocationPermissionDialogOpen(true)
      setCookies(null, 'BlueCollar_UserLocationDisabled', true, {
        expires: 30,
      })
    }
  }

  const locationPermissionDialogClose = () => {
    setLocationPermissionDialogOpen(false)

    if (openMobileSearch) {
      document.getElementById('location-search-mobile')?.focus()
    }
  }

  const onClearLastPosition = () => {
    localStorage.removeItem(localStorageKeywords)
    setLastSearchPositions([])
  }

  const onClearLastLocation = () => {
    localStorage.removeItem(localStorageLocations)
    setLastSearchLocations([])
  }

  const onClearLastPosAndLoc = () => {
    localStorage.removeItem(localStorageKeywordsLocations)
    setLastSearchPosAndLoc([])
  }

  const onClearLastSearchPosAndLocMobile = () => {
    onClearLastPosition()
    onClearLastLocation()
    onClearLastPosAndLoc()
  }

  const anchorScrollIntoView = () => {
    var anchor = window.location.hash.substr(1)
    if (anchor) {
      const el = document.getElementById(anchor)
      if (el) {
        el.scrollIntoView()
      }
    }
  }

  const navbarClasses = classNames('navbar', 'fixed-top', 'navbar-dark', {
    [props.navbarClass]: props.navbarClass,
    [styles['main-navbar']]: true,
    [styles['with-search-head']]: props.mobileSearchHead,
    [styles['service-page-search']]: props.isServicePage,
    [styles.mobileFilterBar]: props.mobileFilterBar,
  })

  const navHeadClasses = classNames({
    [styles[props?.backLink?.className]]: props?.backLink?.className,
    [styles['nav-head']]: true,
    //'d-none d-lg-flex': props.mobileSearchHead,
  })

  const navbarBrandClasses = classNames({
    [styles['navbar-brand']]: true,
    '': props.backLink,
  })

  return (
    <>
      <PageHead
        canonical={props.canonical}
        nextCanonical={props.nextCanonical}
        prevCanonical={props.prevCanonical}
        isDisabledTitlePart={props.isDisabledTitlePart}
        noIndexFollow={props.noIndexFollow}
        noIndexNoFollow={props.noIndexNoFollow}
        title={props.title}
        description={props.description}
        pageType={props.pageType}
      />
      {!props.headerType && (
        <nav className={navbarClasses}>
          <div className={`container ${styles.navContainer}`}>
            <div className={navHeadClasses}>
              <HeaderMenu menuItems={menuItems} />
              <a
                data-test="main-logo"
                href={process.env.NEXT_PUBLIC_MAIN_URL2}
                className={navbarBrandClasses}
              >
                İşin Olsun
              </a>
              {props.headerSetting && (
                <a className={styles['header-setting']} href="/ayarlar">
                  <i className="icon-settings"></i>
                </a>
              )}
            </div>

            <div className={`d-none d-lg-flex ${styles['navbar-form']}`}>
              <HeaderSearch
                lastSearchPositions={lastSearchPositions}
                lastSearchLocations={lastSearchLocations}
                positionList={positionList}
                locationList={locationList}
                positionEmptyLabel={positionEmptyLabel}
                locationEmptyLabel={locationEmptyLabel}
                onPositionChange={onPositionInputChange}
                onLocationChange={onLocationInputChange}
                onPullCurrentLocation={onPullCurrentLocation}
                onPullHomeLocation={onPullHomeLocation}
                showLocationTooltip={showLocationTooltip}
                locationTooltipText={locationTooltipText}
                onCloseTooltip={onCloseTooltip}
                onFormSubmit={searchOnSubmit}
                positionKeyword={positionKeyword}
                locationKeyword={locationKeyword}
                query={props.query || {}}
                onLoginDialogOpen={(e) => onActivationDialogOpen('login', e)}
                onClearLastPosition={onClearLastPosition}
                onClearLastLocation={onClearLastLocation}
                locationProcess={locationProcess}
                pageUri={props.pageUri}
                popularList={popularList}
              />
            </div>
  
            <div className={styles.accountArea}>
              <div
                onClick={(e) => onOpenMobileSearch(e)}
                className={`d-lg-none d-flex ${styles.searchIcon}`}
              >
                <i className="icon-search-thick"></i>
              </div>
              {isLogin ? (
                <AccountHeaderArea
                  activeMenu={props.activeMenu}
                  notificationCount={notificationCount}
                />
              ) : (
                <LoginRegisterButtons
                  onLoginDialogOpen={() => onActivationDialogOpen('login')}
                  onRegisterDialogOpen={() =>
                    onActivationDialogOpen('register')
                  }
                />
              )}
            
            </div>
          </div>

          {props.backLink && (
            <div className={`container ${styles['nav-head']}`}>
              <a
                data-test="back-link"
                className={`${styles['back-link']} container d-lg-none`}
                onClick={
                  props.backLink.isBackHistory ? () => router.back() : null
                }
                href={props.backLink.href && props.backLink.href}
              >
                <svg xmlns="http://www.w3.org/2000/svg">
                  <g
                    fill="none"
                    fillRule="evenodd"
                    stroke="#666"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                  >
                    <path
                      d="M16 6L0 6M6 12L0 6 6 0"
                      transform="translate(1 1)"
                    />
                  </g>
                </svg>
                <span>{props.backLink.text}</span>
              </a>
            </div>
          )}

          {props.mobileSearchHead && (
            <MobileSearchResultHead
              title={props.mobileSearchHead}
              onClick={onOpenMobileSearch}
            />
          )}

          <div className={`${styles['mobile-navbar-form']} d-block d-lg-none`}>
            {props.mobileSearchInput && (
              <div
                className={`${styles['input-wrap']} ${
                  props.mobileSearchHead ? 'd-none' : ''
                }`}
              >
                <div
                  className={`mobile-search-location-input ${styles['search-form-input']}`}
                >
                  <i className={`${styles['icon']} icon-search`}></i>
                  <div
                    id="openMobileNavbarForm"
                    className={styles.openMobileNavbarForm}
                    onClick={(e) => onOpenMobileSearch(e)}
                  >
                    <div className={styles['search-form-text-wrap']}>
                      <span>Pozisyon ve Lokasyon Ara</span>
                    </div>
                  </div>
                  <input
                    type="search"
                    name="searchInput"
                    className="form-control"
                    id="openMobileNavbarFormInput"
                    autoComplete="off"
                    maxLength="250"
                    aria-label="Arama"
                  />
                </div>
              </div>
            )}
            {openMobileSearch && (
              <MobileNavbarFormModal
                open={openMobileSearch}
                onClose={onCloseMobileSearch}
                lastSearch={lastSearchPosAndLoc}
                lastSearchPositions={lastSearchPositions}
                lastSearchLocations={lastSearchLocations}
                positionEmptyLabel={positionEmptyLabel}
                locationEmptyLabel={locationEmptyLabel}
                positionList={positionList}
                locationList={locationList}
                onPositionChange={onPositionInputChange}
                onLocationChange={onLocationInputChange}
                onPullHomeLocation={onPullHomeLocation}
                onPullCurrentLocation={onPullCurrentLocation}
                onFormSubmit={searchOnSubmit}
                query={props.query || {}}
                onLoginDialogOpen={(e) => onActivationDialogOpen('login', e)}
                onClearLastPosAndLoc={onClearLastSearchPosAndLocMobile}
                onClearLastPosition={onClearLastPosition}
                onClearLastLocation={onClearLastLocation}
                positionKeyword={positionKeyword}
                locationKeyword={locationKeyword}
                locationProcess={locationProcess}
                pageUri={props.pageUri}
                popularList={popularList}
              />
            )}
          </div>
        </nav>
      )}

      {props.headerType == 2 && (
        <HomeHeader2
          onLoginDialogOpen={() => onActivationDialogOpen('login')}
        />
      )}

      {props.children}
      {!props.footerDisabled && (
        <Footer2
          responsiveFooterDisable={props.responsiveFooterDisable}
          footerPaddingClose={props.footerPaddingClose}
        />
      )}
      {isLogin && (
        <ResponsiveAccountMenu
          activeMenu={props.activeMenu}
          notificationCount={notificationCount}
        />
      )}
      {!isLogin && props.headerType == 2 && (
        <ResponsiveLoginMenu
          onLoginDialogOpen={() => onActivationDialogOpen('login')}
          onRegisterDialogOpen={() => onActivationDialogOpen('register')}
        />
      )}
      {activationDialog.open && (
        <ActivationDialog
          {...activationDialog}
          onClose={activationDialogClose}
        />
      )}
      {locationPermissionDialogOpen && (
        <LocationPermissionDialog
          onClose={locationPermissionDialogClose}
          open={locationPermissionDialogOpen}
        />
      )}
      <BgImageLazyLoad />
      {agreementDialog.open && (
        <AgreementDialog
          {...agreementDialog}
          onClose={onAgreementClose}
          onConfirmAgreement={onConfirmAgreement}
        />
      )}
      {props.backgroundColor && (
        <style jsx global>{`
          body {
            background-color: ${props.backgroundColor};
          }
        `}</style>
      )}
    </>
  )
}

export default DefaultLayout
