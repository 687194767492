const { useEffect } = require('react')

const BgImageLazyLoad = () => {
  useEffect(() => {
    const handleScroll = () => {
      backgroundImageLazyLoad()
    }
  
    const lazyloadBgImages = document.querySelectorAll('.bg-lazy')
    if (lazyloadBgImages.length < 1) return
  
    backgroundImageLazyLoad()
    window.addEventListener('scroll', handleScroll)
  
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  
  const backgroundImageLazyLoad = () => {
    const lazyloadBgImages = document.querySelectorAll('.bg-lazy')
    if (lazyloadBgImages.length < 1) return
  
    const scrollTop = window.pageYOffset
    const windowHeight = window.innerHeight
  
    lazyloadBgImages.forEach((el) => {
      const offsetTop = el.getBoundingClientRect().top + scrollTop
      if (offsetTop < scrollTop + windowHeight) {
        el.classList.remove('bg-lazy')
        el.classList.add('bg-lazy-loaded')
      }
    })
  }
  

  return null
}

export default BgImageLazyLoad
