import { memo, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

const HeaderMenu = memo(({ menuItems, className }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const mainUrl2 = process.env.NEXT_PUBLIC_MAIN_URL2

  const onClose = () => setMobileMenuOpen(false)
  const onOpen = () => setMobileMenuOpen(true)

  const slideMenuRef = useRef(null)
  const outsideCloseButtonRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        slideMenuRef.current && !slideMenuRef.current.contains(e.target) &&
        (!outsideCloseButtonRef.current || !outsideCloseButtonRef.current.contains(e.target))
      ) {
        setTimeout(() => {
          onClose()
        })
      }
    }
  
    if (mobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }
  
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [mobileMenuOpen, slideMenuRef, outsideCloseButtonRef, onClose])
  

  const componentClass = classNames(styles.headerMenu, className, {
    [styles.open]: mobileMenuOpen,
  })

  return (
    <>
      {mobileMenuOpen && <div className={styles.overlay}></div>}
      <div className={componentClass}>
        {!mobileMenuOpen ? (
          <div
            data-test="hamburger-button"
            className={`${styles.hamburgerBtn}`}
            onClick={onOpen}
          >
            {[1, 2, 3].map((_, index) => (
              <div key={index} className={styles.bar}></div>
            ))}
          </div>
        ) : (
          <div
            ref={outsideCloseButtonRef}
            data-test="close-button"
            className={styles.closeBtn}
            onClick={onClose}
          >
            &times;
          </div>
        )}

        <div ref={slideMenuRef} data-test="slide-menu" className={styles.slideMenu}>
          <div className={styles.menuHeader}>
            <div
              data-test="close-button"
              className={styles.closeBtn}
              onClick={onClose}
            >
              &times;
            </div>
            <a
              title="İşin Olsun"
              href={mainUrl2} className={styles.logo}
            >
              İşin Olsun
            </a>
          </div>
          <div className={styles.divider}></div>
          <div className={styles.menu}>
            {menuItems.map((link, index) => (
              <a key={index} href={link.url}>
                {link.label}
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  )
})

export default HeaderMenu
